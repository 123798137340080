import BaseApi from "src/packages/core/services/BaseApi";
import Logger from "../utils/Logger";

class ChatApi extends BaseApi{
    async handleChat(url:string, payload?:any){
        try{
            const  response  = await this.makeRequest(url, this.POST, payload)
            return response;
        }
        catch(error){
            Logger().trace(error)
        }
    }

    async handleThread(url:string, payload?:any){
        try{
            const  response  = await this.makeRequest(url, this.POST, payload)
            return response;
        }
        catch(error){
            Logger().trace(error)
        }
    }
}

export default ChatApi