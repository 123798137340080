import BaseModel from "src/packages/core/models/BaseModel";

//All App Models will extend this model 
// we have to keep common model functionality in this file
class Model extends BaseModel {
    casts(): {} {
        return {

        }
    }
}

export default Model