type FetchOptions = {
    method?: string;
    headers?: HeadersInit;
    body?: BodyInit;
};

const Fetch = async (url: string, options?: FetchOptions): Promise<Response> => {
    try {
        const response = await fetch(url, options);
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response;
    } catch (error) {
        console.error("Error in useFetch:", error);
        throw error;
    }
};

export default Fetch;