import React from "react";
import  SyntaxHighlighter from "react-syntax-highlighter";
import { atomOneDark as darkStyle } from "react-syntax-highlighter/dist/esm/styles/hljs";

export const highlightCode = (text: any) => {
  const codeRegex = /```([\s\S]*?)```/g;
  let currentIndex = 0;
  let match;

  const elements = [];

  while ((match = codeRegex.exec(text)) !== null) {
    elements.push(
      <React.Fragment key={elements.length}>
        {text.substring(currentIndex, match.index)}
        <SyntaxHighlighter
          className="!leading-relaxed !rounded"
          language="auto"
          style={darkStyle}
        >
          {match[1]}
        </SyntaxHighlighter>
      </React.Fragment>
    );
    currentIndex = match.index + match[0].length;
  }

  elements.push(
    <React.Fragment key={elements.length}>
      {text.substring(currentIndex)}
    </React.Fragment>
  );

  return elements;
};
