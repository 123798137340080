import React, { useEffect, useRef, useState } from "react";
import { ChatNavigation } from "src/components/shared/Nav";
import {
  Avatar,
  Button,
  Textarea,
  mergeClasses,
  Image,
} from "@fluentui/react-components";
import {
  Send24Regular,
  ChevronCircleLeft24Regular,
  ChevronCircleRight24Regular,
  ArrowUp16Filled,
} from "@fluentui/react-icons";
import { Chat, ChatMessage, ChatMyMessage } from "@fluentui-contrib/react-chat";
import { useChatStyles } from "src/components/chat/chat.styles";
import useChat from "src/packages/app/hooks/useChat";
import { highlightCode } from "src/components/shared/CodeHighlighter";
import MessageModel from "src/packages/app/models/MessageModel";

export default function IsmgChat(props: any) {
  const examples = [
    "Tell me about Information Security Media Group",
    "Tell me about CyberEd.io",
  ];

  const [chat, setChat] = useState([]) as any[];
  const [thread, setThread] = useState({})
  const [input, setInput] = useState("");
  const [chatHistory, setChatHistoy] = useState([]) as any[];
  const [title, setTitle] = useState("");
  const [navActive, setNavActive] = useState(false);
  const inputRef = useRef<HTMLTextAreaElement | null>(null);
  const chatContainer = useRef<HTMLDivElement | null>(null);

  //usechat hook is used for api call
  const { handleChat, handleThread } = useChat();

   const createThread = async () => {
     const response = await handleThread(`/threads`, {
       email: props.email,
     });
     const data = await response.json();
     setThread(data.thread);
   };

    useEffect( () => {
        createThread()
    },[])

  //handle sending prompt and receiving response from ai
  const handleSend = async () => {
    if (input.trim() === "") {
      return;
    }
    // setChat([...chat, { role: "user", content: input }]);
   const userMessagemodel = new MessageModel({ role: "user", content: input });

    setChat([...chat, userMessagemodel]);
    
    setInput("");
    if(thread){
    
    const response = await handleChat(`/chat`, {
      thread: thread,
      email: props.email,
      messages: [
        userMessagemodel.getModelData(),
      ],
    });

    const readData = response?.body
      ?.pipeThrough(new TextDecoderStream())
      .getReader();

    let aiRes = "";
    //@ts-ignore
    readData
      ?.read()
      .then(function processText({ done, value } : any) {
        if (done) {
          return;
        }

        for (let i = 0; i < value.length; i++) {
          aiRes += value[i];
        }
        const aiMessageModel = new MessageModel({
          role: "assistant",
          content: aiRes,
        });

        setChat([...chat, userMessagemodel.getModelData(), aiMessageModel.getModelData()]);

        readData?.read().then(processText);
      })
      .catch((error: any) => {
        console.error("Error while reading the stream", error);
      });
    }

    // todo: uncomment this to start history title generation
    // if (!title) {
    //   const createTitle = await fetch(
    //     `${config.apiEndpoint}/history-title?ssoToken=${props.token}`,
    //     {
    //       method: "POST",
    //       headers: {
    //         "Content-Type": "application/json",
    //       },
    //       body: JSON.stringify({
    //         email: props.email,
    //         title: input,
    //       }),
    //     }
    //   );
    //
    //   const data_title = await createTitle.json();
    //   setTitle(data_title.title);
    //   setChatHistoy([...chatHistory, data_title]);
    // }
  };

  //Set input focus when user lands on chat page
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  // UX: adaptive textarea height(maxed to : 200) based on the content provided by user
  const setTextAreaHeight = () => {
     if (inputRef.current) {
       inputRef.current.style.height = "auto"; 
       inputRef.current.style.height = `${inputRef.current.scrollHeight}px`;
     }
  }

  //adpative scroll to latest chat response position
  const updateScrollPosOnChat = () => {
   if(chatContainer.current){
    const { offsetHeight, scrollHeight, scrollTop } = chatContainer.current;
      if (scrollHeight <= scrollTop + offsetHeight + 100) {
        chatContainer.current?.scrollTo(0, scrollHeight);
      }
   }
  };

  useEffect(() => {
    updateScrollPosOnChat();
  }, [chat]);

  // use chat styles
  const styles = useChatStyles();
  return (
    <div
      className={mergeClasses(
        "relative",
        "z-0",
        "flex",
        "h-full",
        "w-full",
        "overflow-hidden",
        styles.chatContainerBackground
      )}
      dir="ltr"
    >
      {/* Navigation  */}
      <div
        className={mergeClasses(
          " h-full",
          "w-[260px]",
          "absolute z-40 lg:relative lg:block",
          styles.chatHistoryBg,
          navActive ? "block" : "hidden"
        )}
      >
        <nav className="flex h-full w-full flex-col px-3 pb-3.5 pt-6">
          <div className="w-full flex justify-end">
            <ChevronCircleLeft24Regular
              className="ml-3 cursor-pointer !block md:!hidden mb-3"
              onClick={() => setNavActive(!navActive)}
            />
          </div>

          <Button
            appearance="primary"
            onClick={() => {
              setChat([]);
              setTitle("");
              createThread();
            }}
            className="w-full"
          >
            Reset Chat
          </Button>
          <ChatNavigation history={chatHistory} />
        </nav>
      </div>

      <div className="relative flex h-full max-w-full flex-1 flex-col overflow-hidden">
        <main className="relative h-full w-full flex-1 overflow-auto transition-width">
          <div>
            <ChevronCircleRight24Regular
              className="ml-3 cursor-pointer !block md:!hidden mt-2"
              onClick={() => setNavActive(!navActive)}
            />
          </div>

          {/* Chat  */}
          <div className="flex h-full flex-col">
            <div
              ref={chatContainer}
              className="relative flex-1 !overflow-y-auto"
            >
              {chat.length > 0 ? (
                <Chat className="!overflow-y-scroll !no-scrollbar">
                  {chat.map((item: any, index: any) => (
                    <div key={index}>
                      {item.role === "assistant" ? (
                        <ChatMessage
                          avatar={
                            <Avatar
                              name="Artificial Intelligence"
                              badge={{ status: "available" }}
                            />
                          }
                        >
                          {highlightCode(item.content)}
                        </ChatMessage>
                      ) : (
                        <ChatMyMessage status="received">
                          {item.content}
                        </ChatMyMessage>
                      )}
                    </div>
                  ))}
                </Chat>
              ) : (
                <div className="flex h-full flex-col items-center justify-center">
                  <div className="mb-12">
                    <Image src="logo-main-v2-no-bg-color-300px-short-height.png" />
                    <h2
                      className={mergeClasses(
                        "mt-5 text-center text-lg",
                        styles.titleColor
                      )}
                    >
                      {" "}
                      Chatbot{" "}
                    </h2>
                  </div>
                </div>
              )}
            </div>

            <div className="w-full pt-2 md:pt-0 md:w-[calc(100%-.5rem)] mx-auto lg:max-w-2xl xl:max-w-3xl">
              {chat.length <= 0 ? (
                <div className="flex justify-center mb-6 mt-6">
                  <div className="grid md:grid-cols-2 grid-cols-1 gap-6">
                    {examples.map((item, index) => (
                      <Button
                        className="relative group w-full !px-4 !py-3 !text-left"
                        appearance="outline"
                        key={index}
                        onClick={() => setInput(item)}
                      >
                        <div className="flex w-full gap-2 items-center justify-center">
                          <div className="flex w-full !items-center !justify-between">
                            <div className="break-words mr-[30px]">{item}</div>
                            <div className="absolute bottom-0 right-0 top-0 flex items-center rounded-xl pl-6 pr-4 opacity-0 group-hover:opacity-100">
                              <div className="rounded-lg">
                                <ArrowUp16Filled className="bg-black bg-opacity-60 text-white !rounded w-5 h-5" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </Button>
                    ))}
                  </div>
                </div>
              ) : (
                <></>
              )}

              <div className="mx-auto max-w-sm md:max-w-2xl lg:max-w-2xl xl:max-w-3xl !pt-6">
                <div className="relative">
                  <Textarea
                    ref={inputRef}
                    size="small"
                    resize="none"
                    value={input}
                    placeholder="How can I assist you?"
                    className={mergeClasses("text-area", styles.promptInput)}
                    onChange={(e) => {
                      setInput(e.target.value);
                      setTextAreaHeight();
                    }}
                    onKeyUp={(e) => {
                      if (e.key === "Enter" && !e.shiftKey) {
                        e.preventDefault(); // Prevent the default action to avoid a newline in textarea
                        if (input.trim()) {
                          handleSend();
                        }
                      }
                    }}
                  />

                  <div className="absolute bottom-2.5 right-4">
                    <Send24Regular
                      className="ml-3 cursor-pointer"
                      onClick={() => (input.trim() ? handleSend() : undefined)}
                    />
                  </div>
                </div>

                {/* We may need this struct for future while integrating chatgpt-4 */}
                {/* <div className="flex justify-between w-full mt-2">
                  <div></div>
                  <Send24Regular
                    className="ml-3 cursor-pointer"
                    onClick={() => (input.trim() ? handleSend() : undefined)}
                  />
                </div> */}
              </div>
              <div className="flex justify-center px-2 py-2">
                <small className={styles.infoText}>
                  Ai can generate incorrect information.
                </small>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
