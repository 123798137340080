import { trim, get, merge } from 'lodash'
import BaseModel from 'src/packages/core/models/BaseModel'
import BaseCollection from "src/packages/core/models/BaseCollection";
import { ObjectToQuery } from "src/packages/core/utility/ObjectToQuery";
import  config  from "src/config"
import Fetch from 'src/packages/core/plugins/Fetch';
import GetToken  from "src/packages/app/utils/GetToken";


export const headers = new Headers()
headers.set('Content-Type', 'application/json')
headers.set('Access-Control-Allow-Origin', '*')
headers.set('Access-Control-Allow-Methods', 'GET,PUT,POST,DELETE')

class BaseApi {
    readonly GET = 'GET'
    readonly POST = 'POST'
    readonly PUT = 'PUT'
    readonly DELETE = 'DELETE'

    constructor() {

    }

    async makeRequest(url: string, type: string, request_options?: object | object[] | BaseModel | BaseCollection, with_bearer: boolean = true) {
        let options = {
            method: '',
            headers: headers,
            // @ts-ignore
            body: JSON.stringify(request_options)
        }
        
        options.method = type

        //get Token from teams-context if app initialized 
        //pass that token on every request
        const token = await GetToken();

        if (get(request_options, 'with_csrf')) {
            const token = await this.withCsrf()
            options.body = merge(options.body, { "csrf-token": token })
        }
        try {
            // @ts-ignore
            const response: any = await Fetch(`${config.apiEndpoint}/${trim(url, '/')}?ssoToken=${token}`, options);
            // console.log(response);
            return response
        }
        catch (error) {
            // Handle other exceptions
            throw error
        }
    }


    setHeaders(localHeaders:any) {
        localHeaders.forEach((header:any) => {
            headers.set(header.key, header.value)
        })
    }

    async withCsrf() {
        const  response  = await this.csrf()
            this.setHeaders([
                {
                    key: 'X-XSRF-TOKEN',
                    value: response
                }
            ])
            return response
    }

    async csrf() {
        return await this.makeRequest('csrf', this.POST)
    }

    //construct url based on the options provide to make full request_url
    getRequestUrl(path: string, url: string, options?: { payload?: any, id?: any, relation?: string }) {
        // @ts-ignore
        let { id, payload, relation } = options;
        const url_query_string = payload ? '?' + ObjectToQuery(payload) : ''
        id = id ? `/${id}` : ''
        relation = relation ? `/${relation}` : ''
        return url ? path + url + id + relation + url_query_string : path + id + relation + url_query_string
    }
}

export default BaseApi