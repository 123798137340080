import { makeStyles, tokens } from '@fluentui/react-components';

export const useChatStyles = makeStyles({
    chatHistoryBg: { backgroundColor: tokens.colorNeutralBackground5Selected },
    titleColor: { color: tokens.colorNeutralForeground1 },
    helpText: { color: tokens.colorNeutralForeground1 },
    promptInput: { width: "100%" },
    infoText: { color: tokens.colorNeutralForeground4 },
    chatContainerBackground: { backgroundColor: tokens.colorNeutralBackground3Selected }
});
