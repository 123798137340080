import { Warning24Regular } from "@fluentui/react-icons";

export default function Unauthorized(){
    return (
      <div className="min-h-screen flex flex-col justify-center items-center">
        <div className="mb-6">
            <Warning24Regular className="text-orange-600 w-12 h-12" />
        </div>
        <div className="text-black font-medium">
          <h2>
            Unauthorized access detected! Please use the provided interface and
            features to navigate within the app
          </h2>
        </div>
      </div>
    );
}