import { useContext, useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode";
import { TeamsFxContext } from "src/pages/Context";
import IsmgChat from "src/components/chat";
import { app, authentication } from "@microsoft/teams-js";
import Unauthorized from "src/components/shared/UnAuthorized";

export default function Tab() {
  const { themeString } = useContext(TeamsFxContext);
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState("") as any;
  const [ssoToken, setSsoToken] = useState<string>();

  useEffect(() => {
    authentication
      .getAuthToken({
        resources: [process.env.TAB_APP_URI as string],
        silent: false,
      } as authentication.AuthTokenRequestParameters)
      .then((token) => {
        const decoded: { [key: string]: any } = jwtDecode(token) as any;
        setName(decoded!.name);
        setSsoToken(token);
        app.notifySuccess();
      })
      .catch((message) => {
        app.notifyFailure({
          reason: app.FailedReason.AuthFailed,
          message,
        });
      });
  }, []);

  const getUserEmail = async () => {
    const context = await app.getContext();
    const email = context.user?.userPrincipalName;
    setEmail(email);
  };

  getUserEmail();

  return (
    <div
      className={
        themeString === "default"
          ? "light"
          : themeString === "dark"
          ? "dark"
          : "contrast"
      }
    >
      {
        ssoToken ? <IsmgChat token={ssoToken} email={email} /> : <Unauthorized />
      }
    </div>
  );
}
