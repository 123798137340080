import { merge } from "lodash";
import Model from "src/packages/app/models/Model";

class MessageModel extends Model {
    casts() {
        return merge(super.casts(), {
            id: "number",
            role: "string",
            content: "string",
        })
    }
}

export default MessageModel;