import { app, authentication } from "@microsoft/teams-js";
// import { jwtDecode } from "jwt-decode";

const GetToken = async () => {
    try {
        const initialized = app.isInitialized();

        if (initialized) {
            const token = await authentication.getAuthToken({
                resources: [process.env.TAB_APP_URI as string],
                silent: false,
            } as authentication.AuthTokenRequestParameters);
            // const decoded: { [key: string]: any } = jwtDecode(token) as any;
            app.notifySuccess();
            return token;
        }
    } catch (error:any) {
        console.error("Error in GetToken:", error);
        app.notifyFailure({
            reason: app.FailedReason.AuthFailed,
            message: error.message || "Authentication failed",
        });
    }
};

export default GetToken;
