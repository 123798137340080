import useApi from "src/packages/app/hooks/useApi";
import Logger from "../utils/Logger";

const useChat = () => {
    const chatApi = useApi().chat;
    return {
        handleChat: async (url:string, payload?:any) =>{
            try{
                let data = await chatApi.handleChat(url, payload)
                return data
            }catch(e){
                Logger().trace(e)
            }
        },
        handleThread: async (url:string, payload?:any) =>{
            try{
                let data = await chatApi.handleThread(url, payload)
                return data
            }catch(e){
                Logger().trace(e)
            }
        } 
    }
}

export default useChat;