import * as React from "react";
import { useChatStyles } from "src/components/chat/chat.styles";
import { mergeClasses } from "@fluentui/react-components";
interface Ihistory {
  history: any[];
}
export const ChatNavigation: React.FunctionComponent<Ihistory> = ({
  history,
}) => {
  const styles = useChatStyles();
  return (
    <div>
      <div className="chat-history-wrapper">
        {history?.map((item, index) => (
          <div className="flex mb-3">
            <div className={mergeClasses("mr-2", styles.helpText)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-message"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M8 9h8" />
                <path d="M8 13h6" />
                <path d="M18 4a3 3 0 0 1 3 3v8a3 3 0 0 1 -3 3h-5l-5 3v-3h-2a3 3 0 0 1 -3 -3v-8a3 3 0 0 1 3 -3h12z" />
              </svg>
            </div>
            <p
              key={index}
              className={mergeClasses("mb-2", "!line-clamp-1", styles.helpText)}
            >
              {item.title}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};
